<template>
  <app-layout>
    <template v-slot:navigationLeftDrawer>
      <category-drawer></category-drawer>
    </template>
  </app-layout>
</template>

<script>
import { baseHomeMixin } from "@/mixins/shared/base/home/baseHomeMixin";
export default {
  name: "CategoryHome",
  mixins: [baseHomeMixin],
  components: {
    CategoryDrawer: () => import("@/views/category/CategoryDrawer")
  },
  computed: {
    selectApplicationName() {
      return this.applicationNames.Category;
    }
  }
};
</script>
